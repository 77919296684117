import {
  faBlog,
  faBook,
  faCalendar,
  faGear,
  faHome,
  faSquarePollVertical,
  faUserDoctor,
  faUsersRectangle,
} from "@fortawesome/free-solid-svg-icons"
import { capitalize } from "lodash"
import { Navigate, Outlet } from "react-router-dom"

import SignupClinicianPreamble from "@/core/Authentication/components/Signup/Screens/SignupClinicianPreamble.tsx"
import SignupClinicianVerification from "@/core/Authentication/components/Signup/Screens/SignupClinicianVerification.tsx"
import SignupExperienceSelection from "@/core/Authentication/components/Signup/Screens/SignupExperienceSelection.tsx"
import SignupOrgConsent from "@/core/Authentication/components/Signup/Screens/SignupOrgConsent.tsx"
import SignupOrgSelection from "@/core/Authentication/components/Signup/Screens/SignupOrgSelection.tsx"
import SignupTermsCapture from "@/core/Authentication/components/Signup/Screens/SignupTermsCapture.tsx"
import ClientsProvider from "@/providers/ClientsProvider.tsx"
import GenericWrapper from "@/providers/GenericWrapper.tsx"

import SecondaryNavContainer from "@components/SecondaryNavContainer/SecondaryNavContainer.tsx"

import CreateOrg from "@features/Admin/Orgs/CreateOrg.tsx"
import ClientOverview from "@features/Clients/ClientOverview.tsx"
import Appointments from "@features/Coaches/Appointments.tsx"
import AssignmentResponse from "@features/Coaches/AssignmentResponse.tsx"
import ContentPosts from "@features/Content/ContentPosts.tsx"
import Post from "@features/Content/Posts/Post.tsx"
import ContentLibrary from "@features/ContentLibrary/ContentLibrary.tsx"
import ContentLibraryPost from "@features/ContentLibrary/ContentLibraryPost.tsx"
import Diagnostics from "@features/Diagnostics/Diagnostics.tsx"
import ClientsPages from "@features/Home/ClientsPages.tsx"
import Home from "@features/Home/Home.tsx"
import ClientHome from "@features/Home/Homes/ClientHome.tsx"
import ChangeLog from "@features/Legal/Pages/ChangeLog.tsx"
import PrivacyPolicy from "@features/Legal/Pages/PrivacyPolicy.tsx"
import TermsOfService from "@features/Legal/Pages/TermsOfService.tsx"
import Onboarding from "@features/Onboarding/Onboarding.tsx"
import VerificationDisplay from "@features/Onboarding/VerificationDisplay.tsx"
import MyCoach from "@features/Patients/MyCoach/MyCoach.tsx"
import OnboardingCheckout from "@features/Payments/OnboardingCheckout.tsx"
import RenewalCheckout from "@features/Payments/RenewalCheckout.tsx"
import RenewalDisplay from "@features/Payments/RenewalDisplay.tsx"
import ClientSettingsGeneral from "@features/Settings/ClientSettingsGeneral.tsx"
import CoachSettingsGeneral from "@features/Settings/CoachSettingsGeneral.tsx"
import RelationshipsOverviewCard from "@features/Settings/RelationshipsOverviewCard.tsx"
import UserSettingsGeneral from "@features/Settings/UserSettingsGeneral.tsx"
import UserSettingsNotifications from "@features/Settings/UserSettingsNotifications.tsx"
import TestPage from "@features/TestPage/TestPage.tsx"
import TimelineOverview from "@features/Timeline/TimelineOverview.tsx"

import { ROLES } from "./constants/roles.const.ts"
import Login from "./core/Authentication/Login.tsx"
import PostSignupFlow from "./core/Authentication/PostSignupFlow.tsx"
import Register from "./core/Authentication/Register.tsx"
import DevSitemap from "./core/DevSitemap/DevSitemap.tsx"
import AcceptInvitation from "./core/Invitations/AcceptInvitation.tsx"
import BasicLayoutWithAuth from "./core/Layout/BasicLayoutWithAuth.tsx"
import MaintenanceWindow from "./core/Maintenance/MaintenanceWindow.tsx"
import FeedAssignmentRenderer from "./features/Assignment/FeedAssignmentRenderer.tsx"
import FeedAssignmentDetailProvider from "./features/Assignment/providers/FeedAssignmentDetailProvider.tsx"
import CareCircle from "./features/CareCircleNew/CareCircle.tsx"
import ClinicianProfile from "./features/Profiles/ClinicianProfile.tsx"
import UserSettingsRegion from "./features/Settings/UserSettingsRegion.tsx"
import TestTranslationPage from "./i18n/TestTranslationPage.tsx"

const routes = ({ nomenclature }: { nomenclature: Nomenclature }) =>
  [
    {
      path: "/:date?",
      element: <Home />,
      navigation: {
        label: "Home",
        icon: faHome,
        to: "/",
      },
    },
    {
      path: "/maintenance",
      element: <MaintenanceWindow />,
      access: {
        public: true,
      },
      layout: false,
    },
    {
      path: "/p/feed/survey/:feedItemId",
      element: (
        <FeedAssignmentDetailProvider>
          <FeedAssignmentRenderer />
        </FeedAssignmentDetailProvider>
      ),
      access: {
        audiences: [ROLES.CLIENT],
      },
    },
    {
      path: "/p/my_trainer",
      element: <MyCoach />,
      access: {
        audiences: [ROLES.CLIENT],
      },
      orgs: ["personily"],
      navigation: {
        label: `My ${capitalize(nomenclature.actors.coach.singular)}`,
        icon: faUserDoctor,
      },
    },
    {
      path: "/p/timeline",
      element: <TimelineOverview />,
      access: {
        audiences: [ROLES.CLIENT],
      },
      navigation: {
        label: "Timeline",
        icon: faSquarePollVertical,
      },
    },
    {
      path: "/p/library",
      element: <ContentLibrary />,
      access: {
        audiences: [ROLES.ADMIN],
      },
      navigation: {
        label: "Library",
        icon: faBook,
      },
    },
    {
      path: "/p/library/:postId",
      element: <ContentLibraryPost />,
      access: {
        public: true,
      },
    },
    {
      path: "/p/connect",
      element: <CareCircle />,
      access: {
        audiences: [ROLES.CLIENT],
      },
      navigation: {
        label: "Care Circle",
        icon: faUsersRectangle,
      },
    },
    {
      path: "/p/clinicians/:clinicianId",
      element: <ClinicianProfile />,
      access: {
        audiences: [ROLES.CLIENT, ROLES.ADMIN],
      },
    },
    {
      path: "/p/responses/:responseId",
      element: <AssignmentResponse target={"self"} />,
      access: {
        audiences: [ROLES.CLIENT],
      },
    },
    //* Provider Routes *//
    {
      path: "/pr/clients",
      element: (
        <GenericWrapper
          Provider={(children) => <ClientsProvider>{children}</ClientsProvider>}
          Component={() => <ClientsPages />}
        />
      ),

      access: {
        audiences: [ROLES.COACH, ROLES.RA, ROLES.CLINICIAN],
      },
      navigation: {
        label: capitalize(nomenclature.actors.patient.plural),
        icon: faUsersRectangle,
      },
    },
    {
      path: "/pr/clients/:clientId",
      element: (
        <GenericWrapper
          Provider={(children) => <ClientsProvider>{children}</ClientsProvider>}
          Component={() => <ClientOverview />}
        />
      ),

      access: {
        audiences: [ROLES.COACH, ROLES.RA, ROLES.CLINICIAN],
      },
    },
    {
      path: "/pr/responses/:responseId",
      element: <AssignmentResponse />,
      access: {
        audiences: [ROLES.COACH, ROLES.CLINICIAN, ROLES.RA],
      },
    },
    {
      path: "/pr/appointments",
      element: <Appointments />,
      access: {
        audiences: [ROLES.COACH],
      },
      navigation: {
        label: "Appointments",
        icon: faCalendar,
      },
    },
    {
      path: "/admin/content/:contentClass",
      element: <ContentPosts />,
      access: {
        public: false,
        audiences: ["admin"],
      },
      navigation: {
        to: "/admin/content/articles",
        label: "Content",
        icon: faBlog,
      },
    },
    {
      path: "/admin/content/posts/:postId/:draftId",
      element: <Post />,
      access: {
        public: false,
        audiences: ["admin"],
      },
    },
    {
      path: "/legal",
      title: "Legal",
      element: <Outlet />,
      router: {
        children: [
          {
            path: "",
            element: <Navigate to={"/legal/terms"} />,
          },
          {
            path: "terms",
            element: <TermsOfService />,
            label: "Terms of Service",
            access: {
              public: true,
            },
          },
          {
            path: "privacy",
            element: <PrivacyPolicy />,
            label: "Privacy Policy",
            access: {
              public: true,
            },
          },
          {
            path: "changelog",
            element: <ChangeLog />,
            label: "Change Log",
            access: {
              public: true,
            },
          },
        ],
      },
    },
    {
      path: "/settings",
      element: <SecondaryNavContainer categoryLabel={"Settings"} selector={"/settings"} />,
      title: "Settings",
      navigation: {
        to: "/settings/general",
        icon: faGear,
        label: "Settings",
      },
      router: {
        children: [
          {
            path: "",
            element: <Navigate to={"/settings/general"} />,
            access: {
              public: true,
            },
          },
          {
            path: "general",
            element: <UserSettingsGeneral />,
            label: "General",
            access: {
              public: true,
            },
          },
          {
            path: "region",
            element: <UserSettingsRegion />,
            label: "Language and Region",
            access: {
              public: true,
            },
          },
          {
            path: "user_profile",
            element: <ClientSettingsGeneral />,
            label: "Profile",
            access: {
              public: false,
              audiences: ["client"],
            },
          },
          {
            path: "notifications",
            element: <UserSettingsNotifications />,
            label: "Notifications",
            access: {
              public: true,
            },
          },
          {
            path: "provider-profile",
            element: <CoachSettingsGeneral />,
            label: "Provider Profile",
            access: {
              public: false,
              audiences: [ROLES.COACH, ROLES.CLINICIAN],
            },
          },
          {
            path: "permissions",
            element: <RelationshipsOverviewCard />,
            label: "Permissions",
            access: {
              public: false,
              audiences: [ROLES.CLIENT],
            },
          },
        ],
      },
    },
    {
      path: "/subscriptions",
      element: <Outlet />,
      title: "Subscriptions",
      router: {
        children: [
          {
            path: "",
            element: <Navigate to={"/subscriptions/subscribe"} />,
          },
          {
            path: "subscribe",
            element: <RenewalDisplay />,
            label: "Subscribe",
            access: {
              public: false,
            },
          },
          {
            path: "checkout",
            element: <RenewalCheckout />,
            label: "Checkout",
            access: {
              public: false,
            },
          },
        ],
      },
    },
    {
      path: "/onboarding",
      element: <Outlet />,
      title: "Onboarding",
      router: {
        children: [
          {
            path: "",
            element: <Navigate to={"/onboarding/onboarding"} />,
          },
          {
            path: "onboarding",
            element: <Onboarding />,
            label: "Onboarding",
            access: {
              public: false,
            },
          },
          {
            path: "checkout",
            element: <OnboardingCheckout />,
            label: "Checkout",
            access: {
              public: false,
            },
          },
        ],
      },
      layout: BasicLayoutWithAuth,
    },
    {
      path: "/clinician-verification",
      element: <VerificationDisplay />,
      access: {
        public: false,
        audiences: [ROLES.CLINICIAN],
      },
    },
    {
      path: "/login",
      element: <Login />,
      access: {
        public: true,
      },
      layout: false,
    },
    {
      path: "/register",
      element: <Outlet />,
      access: {
        public: true,
      },
      layout: false,
      router: {
        children: [
          {
            path: "",
            element: <Navigate to={"/register/experience"} />,
          },
          {
            path: "experience",
            element: <SignupExperienceSelection />,
            access: {
              public: true,
            },
          },
          {
            path: "clinician",
            element: <SignupClinicianPreamble />,
            access: {
              public: true,
            },
          },
          {
            path: "clinician/verification",
            element: <SignupClinicianVerification />,
            access: {
              public: true,
            },
          },
          {
            path: "create-account",
            element: <Register />,
            access: {
              public: true,
            },
          },
          {
            path: "terms",
            element: <SignupTermsCapture />,
            access: {
              public: true,
            },
          },
          {
            path: "consent",
            element: <SignupOrgConsent />,
            access: {
              public: true,
            },
          },
          {
            path: "organization",
            element: <SignupOrgSelection />,
            access: {
              public: true,
            },
          },
        ],
      },
    },
    {
      path: "/register/post-signup-flow",
      element: <PostSignupFlow />,
      access: {
        public: true,
      },
      layout: BasicLayoutWithAuth,
    },
    {
      path: "/accept-invitation",
      element: <AcceptInvitation />,
      access: {
        public: false,
        audiences: [ROLES.CLIENT, ROLES.CLINICIAN, ROLES.COACH, ROLES.RA],
      },
      layout: BasicLayoutWithAuth,
    },
    {
      path: "/sitemap-dev",
      element: <DevSitemap />,
      devOnly: true,
    },
    {
      path: "/diagnostics/info",
      element: <Diagnostics />,
      layout: false,
      access: {
        public: true,
      },
    },
    {
      path: "/demo/client-home",
      element: <ClientHome />,
      access: {
        public: false,
        audiences: ["admin"],
      },
    },
    {
      path: "/test/translation",
      element: <TestTranslationPage />,
      access: {
        public: false,
        audiences: ["admin"],
      },
    },
    {
      path: "/test",
      element: <TestPage />,
      access: {
        public: false,
        audiences: ["admin"],
      },
    },
    {
      path: "/orgs/create",
      element: <CreateOrg />,
      access: {
        public: false,
        audiences: ["superuser"],
      },
    },
  ] as Route[]

export default routes
